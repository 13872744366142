import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import tickers from '../features/slices/tickersSlice';
import irrAnalytics from "../features/slices/irrAnalyticsSlice";
import correlationChart from "../features/slices/correlationChartSlice";
import tableStatistic from "../features/slices/tableStatisticSlice"
import token from "../features/slices/tokenSlice";
import user from "../features/slices/userSlice";
import landingChart from "../features/slices/landingChartSlice";
import error from '../features/slices/errorSlice'

export const store = configureStore({
    reducer: {
        tickers, irrAnalytics, landingChart, correlationChart, tableStatistic, token, user, error
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
