import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../app/hooks";
import {Outlet, useNavigate} from "react-router-dom";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const PrivateRoute = () => {
    const token = useAppSelector(state => state.token)
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);

    const handleCancel = () => {
        setOpen(false);
        navigate('/');
    };

    const handleLogin = () => {
        setOpen(false);
        navigate('/login');
    };

    useEffect(() => {
        if (!token) {
            setOpen(true);
        }
    }, [token]);

    return (
        token ? <Outlet/> :
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Authorization required"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        If you want to continue working with the advanced functionality of the application, please log
                        in.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleLogin} autoFocus>
                        Go to login
                    </Button>
                </DialogActions>
            </Dialog>

    )
};

export default PrivateRoute;