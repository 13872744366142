import * as React from 'react';
import Box from '@mui/material/Box';
import LoginBtn from "./header_and_footer/LoginBtn";
import style from '../css_modules/header/header.module.css';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {red} from "@mui/material/colors";
import Navigation from "./header_and_footer/Navigation";
import Logo from "./header_and_footer/Logo";
import {useAppSelector} from "../app/hooks";
import UserBtn from "./header_and_footer/UserBtn";

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: red[900]
        },
    },
});

export default function Header() {

    const token = useAppSelector(state => state.token)

    return (
        <ThemeProvider theme={theme}>
            <Box className={style.header} display='flex' justifyContent='space-between'
                 alignContent='center'>
                <Logo marginLeft={'50px'}/>
                <Navigation/>
                <Box sx={{marginRight: '50px'}}>
                    {!token ? <LoginBtn/> : <UserBtn/>}
                </Box>
            </Box>
        </ThemeProvider>
    );
}
