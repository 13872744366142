import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../app/hooks";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import AspectRatio from "@mui/joy/AspectRatio";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import ChangeDataBtn from "./ChangeDataBtn";
import ChangePasswordBtn from "./ChangePasswordBtn";
import ChangeAvatarBtn from "./ChangeAvatarBtn";
import DeleteAccountBtn from "./DeleteAccountBtn";
import {useNavigate} from "react-router-dom";

const UserProfile = () => {
    const [newPhotoUrl, setNewPhotoUrl] = useState('');
    const user = useAppSelector(state => state.user)
    const navigate = useNavigate();

    const changeAvatar = (avatar: string) => {
        setNewPhotoUrl(avatar)
    }

    useEffect(() => {
        if (!user.login) {
            navigate('/')
        }
    }, [user, navigate])

    return (

        <Box
            sx={{
                mt: 8,
                width: '50%',
                position: 'relative',
                overflow: {xs: 'auto', sm: 'initial'},
            }}
        >
            <Card
                orientation="horizontal"
                sx={{
                    width: '100%',
                    flexWrap: 'wrap',
                    [`& > *`]: {
                        '--stack-point': '500px',
                        minWidth:
                            'clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)',
                    },
                    // make the card resizable for demo
                    overflow: 'auto',
                    resize: 'horizontal',
                }}
            >
                <AspectRatio flex ratio="1" maxHeight={182} sx={{minWidth: 182}}>
                    <img
                        src={newPhotoUrl || "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"}
                        srcSet={newPhotoUrl ? `${newPhotoUrl}?auto=format&fit=crop&w=286&dpr=2 2x` : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286&dpr=2 2x"}
                        loading="lazy"
                        alt=""
                    />
                </AspectRatio>
                <CardContent>
                    <Box sx={{
                        mt: 2,
                        display: 'flex',
                        gap: 1.5,
                        '& > button': {flex: 1},
                        justifyContent: 'space-between'
                    }}>
                        <ChangeAvatarBtn changeAvatar={changeAvatar}/>
                        <DeleteAccountBtn/>
                    </Box>
                    <Typography fontSize="xl" fontWeight="lg">
                        Login/email: {user.login}
                    </Typography>
                    <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
                        Name: {user.firstName}
                    </Typography>
                    <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
                        Surname: {user.lastName}
                    </Typography>
                    <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
                        Role: {user.roles?.map((r: string) => r + " ")}
                    </Typography>
                    <Box sx={{mt: 2, display: 'flex', gap: 1.5, '& > button': {flex: 1}}}>
                        <ChangeDataBtn/>
                        <ChangePasswordBtn/>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default UserProfile;