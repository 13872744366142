import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import CorrelationBox from "./statistics/CorrelationBox";
import TextCorrelation from "./statistics/TextCorrelation";
import {useAppDispatch} from "../app/hooks";
import {eraseErrorMessage} from "../features/slices/errorSlice";


const Correlation = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(eraseErrorMessage())
    })

    return (
        <Box paddingY='100px'>
            <TextCorrelation/>
            <CorrelationBox/>
        </Box>

    );
}

export default Correlation;