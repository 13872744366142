import * as React from 'react';
import {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import style from '../../css_modules/accounting/login.module.css'
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useNavigate} from "react-router-dom";
import {registerUser} from "../../features/api/accountActions";
import {eraseErrorMessage} from "../../features/slices/errorSlice";

interface Props {
    changeToSignIn: () => void
}

export default function SignUp({changeToSignIn}: Props) {

    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.token)
    const navigate = useNavigate();
    const [isValid, setIsValid] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const handleEmailChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setPassword(event.target.value);
    };

    const handleFirstNameChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setLastName(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let login = data.get('email') as string
        const firstName = data.get('firstName') as string
        const lastName = data.get('lastName') as string
        const password = data.get('password') as string
        try {
            login = login.toLowerCase()
            await dispatch(registerUser({login, firstName, lastName, password}));
        } catch (e: any) {
            if (e.codeNumber === 409) {
                setEmail('')
                setPassword('')
                setFirstName('')
                setLastName('')
            }
        }
    };

    useEffect(() => {
        const emailValid = !!(email && email.includes('@'));
        const passwordValid = !!(password && password.length >= 8 && /[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password) && /[!@#$%^&*]/.test(password));
        const firstNameValid = !!(firstName && firstName !== '');
        const lastNameValid = !!(lastName && lastName !== '');
        setIsValid(emailValid && passwordValid && firstNameValid && lastNameValid);
    }, [email, password, firstName, lastName]);

    useEffect(() => {
        if (isValid) {
            dispatch(eraseErrorMessage())
        }
    }, [isValid, dispatch])

    useEffect(() => {
        if (token) {
            navigate('/'); // Redirect to your desired route
        }
    }, [navigate, token]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box className={style.loginWindow}>
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} autoComplete="off" sx={{mt: 3}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                value={firstName}
                                onChange={handleFirstNameChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                                value={lastName}
                                onChange={handleLastNameChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={password}
                                onChange={handlePasswordChange}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={!isValid}
                        sx={{
                            mt: 3, mb: 2,
                            backgroundColor: '#7a1212',
                            '&:hover': {
                                backgroundColor: '#3e4953',
                                border: '1px solid #3e4953'
                            }
                        }}
                    >
                        Sign Up
                    </Button>
                    <Typography sx={{fontSize: '12px'}}>The password must be at least 8 characters long and include at
                        least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character
                        !@#$%^&*</Typography>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="#" variant="body2" onClick={changeToSignIn}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}
