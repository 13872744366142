import {createAsyncThunk} from "@reduxjs/toolkit";
import {AnalyticsStockDataWithIrr, GetIndexDataDto, RequestRatesByIndexesBetween} from "../../utils/types";
import {RootState} from "../../app/store";
import {axiosBase} from "../../utils/config";


type ApyCombinedData = {
    irrData: AnalyticsStockDataWithIrr,
    lineData: GetIndexDataDto[]
}

export const fetchAnalyticsWithIrrThunk = createAsyncThunk<ApyCombinedData, RequestRatesByIndexesBetween, { state: RootState, rejectValue: string }>(
    'irrAnalytics/data',
    async (request: RequestRatesByIndexesBetween, ThunkAPI) => {
        const abortController = new AbortController();
        const {signal} = abortController;

        try {
            const token = ThunkAPI.getState().token;

            const responseApy = await axiosBase.post<AnalyticsStockDataWithIrr>('/communication/index/apy', request, {
                headers: {
                    'Authorization': token
                },
                signal
            });
            const responseLine = await axiosBase.post<GetIndexDataDto[]>('/communication/index/data', {
                indexs: request.indexs,
                from: request.from,
                to: request.to
            }, {
                headers: {
                    'Authorization': token
                },
                signal
            })
            const apy = Array.isArray(responseApy.data) ? responseApy.data[0] : responseApy.data;
            return {irrData: apy, lineData: responseLine.data}; // The response data has only one item in the array
        } catch (error) {
            abortController.abort();
            // return ThunkAPI.rejectWithValue(error instanceof Error ? error.message : 'unknown error fetching APY data');
            throw error;
        }
    }
)