import React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Menu from "@mui/material/Menu";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import style from "../../css_modules/header/header.module.css";
import Button from "@mui/material/Button";

const StatisticsBtn = () => {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{flexGrow: 0}} display='flex' justifyContent='space-between'
             alignContent='center'>
            <Button title="Open settings" onClick={handleOpenUserMenu} className={style.navBtn}>
                Statistics
            </Button>
            <Menu
                sx={{mt: '45px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem component={Link} to='/statistics/correlation' key='correlation' onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">Correlation chart</Typography>
                </MenuItem>
                <MenuItem component={Link} to='/statistics/candlestick' key='candlestick' onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">Candlestick chart</Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default StatisticsBtn;