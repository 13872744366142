import {useAppDispatch} from "../../app/hooks";
import {deleteToken} from "../slices/tokenSlice";
import {deleteUser} from "../slices/userSlice";
import {useEffect} from "react";


const UseTokenExpiration = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const checkTokenExpiration = () => {
            const tokenTimestamp = JSON.parse(localStorage.getItem('tokenTimestamp') || '0');
            if (Date.now() - tokenTimestamp > 1000 * 60 * 60) {
                dispatch(deleteToken());
                dispatch(deleteUser());
                localStorage.removeItem('token');
                localStorage.removeItem('userData')
                localStorage.removeItem('tokenTimestamp');
            }
        };

        // Check token expiration immediately and set an interval to check every 60 min
        checkTokenExpiration();
        const intervalId = setInterval(checkTokenExpiration, 1000 * 60 * 60);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [dispatch]);
};

export default UseTokenExpiration;