import {createSlice} from "@reduxjs/toolkit";
import {changePassword, deleteAccount, fetchUser, registerUser} from "../api/accountActions";

const storedUserData = localStorage.getItem('token');
const initialState = storedUserData ? storedUserData : '';

const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        deleteToken: () => ('')
    },
    extraReducers: builder => {
        builder
            .addCase(registerUser.fulfilled, (state, action) => {
                return action.payload?.token
            })
            .addCase(registerUser.rejected, (state, action) => {
                throw action.payload
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                return action.payload.token
            })
            .addCase(fetchUser.rejected, (state, action) => {
                throw action.payload
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                return action.payload
            })
            .addCase(changePassword.rejected, (state, action) => {
                throw action.payload
            })
            .addCase(deleteAccount.fulfilled, () => {
                return initialState
            })
            .addCase(deleteAccount.rejected, (state, action) => {
                throw action.payload
            })
    }
})

export const {deleteToken} = tokenSlice.actions
export default tokenSlice.reducer