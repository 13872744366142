import React from 'react';
import {Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    }

    return (
        <Box display={"flex"} justifyContent="center"
             alignItems="center"
             minHeight="100vh">
            <Box textAlign="center">
                <Typography sx={{
                    fontWeight: 900,
                    fontSize: '60px',
                    lineHeight: 1,
                    color: 'darkgray',
                    mb: '50px'
                }}>404</Typography>
                <Typography sx={{
                    fontWeight: 900,
                    fontSize: '50px',
                    mb: '30px'
                }}>You have found a secret place.</Typography>
                <Typography sx={{
                    fontSize: '20px',
                    color: 'darkgray',
                    maxWidth: '800px',
                    margin: 'auto',
                    mb: '30px'
                }}>
                    Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has
                    been moved to another URL.
                </Typography>
                <Box marginTop={2} onClick={handleClick}>
                    <Button variant="contained" sx={{
                        backgroundColor: '#7a1212',
                        '&:hover': {
                            backgroundColor: '#3e4953',
                            border: '1px solid #3e4953'
                        },
                    }}>
                        Take me back to home page
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default NotFound;