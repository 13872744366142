import axios from "axios";
import {base_url, serverErrors} from "./constants";
import {store} from "../app/store";
import {eraseErrorMessage, setErrorMessage} from "../features/slices/errorSlice";

//Axios config
export const axiosBase = axios.create({
    baseURL: base_url,
})

axiosBase.interceptors.response.use(
    (res) => {
        store.dispatch(eraseErrorMessage())
        return res
    },
    (err) => {
        return checkResponseError(err)
    }
)

const checkResponseError = (err: any) => {
    if (axios.isAxiosError(err)) {
        if (Object.keys(serverErrors).includes(`error${err.response?.status}`)) {
            store.dispatch(setErrorMessage(serverErrors[`error${err.response?.status}`]))
        } else {
            store.dispatch(setErrorMessage('An unexpected error occurred'))
        }
    }
    return Promise.reject(err);
}
