import React from 'react';
import {useAppSelector} from "../../app/hooks";
import {eraseErrorMessage} from "../slices/errorSlice";
import AlertComponent from "../../components/helperComponents/AlertComponent";

interface ErrorMsgWrapperComponentProps {
    top?: string;
    positionRight?: string;

    [key: string]: any;
}

export const ErrorMsgWrapper = (WrappedComponent: React.FC) => {
    const ErrorMsgWrapperComponent: React.FC<ErrorMsgWrapperComponentProps> = ({top, positionRight, ...props}) => {
        const error = useAppSelector((state) => state.error);

        return (
            <div>
                {error && <AlertComponent
                    msg={error}
                    action={eraseErrorMessage}
                />}
                <WrappedComponent {...props}/>
            </div>
        );
    };

    return ErrorMsgWrapperComponent;
}
