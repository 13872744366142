import React, {useEffect, useState} from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import Stack from '@mui/joy/Stack';
import {changePassword} from "../../features/api/accountActions";
import {useAppDispatch} from "../../app/hooks";
import TextField from "@mui/material/TextField";
import {eraseErrorMessage, setErrorMessage} from "../../features/slices/errorSlice";
import Typography from "@mui/material/Typography";

const ChangePasswordBtn = () => {
    const [open, setOpen] = React.useState<boolean>(false);
    const dispatch = useAppDispatch()
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValid, setIsValid] = useState(false);

    const handleOldPasswordChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setOldPassword(event.target.value);
    };

    const handleNewPasswordChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setConfirmPassword(event.target.value);
    };

    useEffect(() => {
        const oldPasswordValid = !!(oldPassword && oldPassword !== '');
        const newPasswordValid = !!(newPassword && newPassword.length >= 8 && /[a-z]/.test(newPassword) && /[A-Z]/.test(newPassword) && /[0-9]/.test(newPassword) && /[!@#$%^&*]/.test(newPassword));
        const confirmPasswordValid = !!(confirmPassword && confirmPassword.length >= 8 && /[a-z]/.test(confirmPassword) && /[A-Z]/.test(confirmPassword) && /[0-9]/.test(confirmPassword) && /[!@#$%^&*]/.test(confirmPassword));
        setIsValid(oldPasswordValid && newPasswordValid && confirmPasswordValid);
    }, [oldPassword, newPassword, confirmPassword]);


    useEffect(() => {
        if (isValid) {
            dispatch(eraseErrorMessage())
        }
    }, [isValid, dispatch])

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                color="neutral"
                onClick={() => {
                    setOpen(true)
                    dispatch(eraseErrorMessage())
                }}
            >
                Change password
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog>
                    <DialogTitle>Please input old and new passwords</DialogTitle>
                    <form
                        onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            const data = new FormData(event.currentTarget)
                            const oldPassword = data.get('oldPassword') as string
                            const newPassword = data.get('newPassword') as string
                            const confirmPassword = data.get('confirmPassword') as string
                            if (!(newPassword === confirmPassword)) {
                                dispatch(setErrorMessage('Passwords do not match'))
                                setNewPassword('')
                                setConfirmPassword('')
                                setOpen(false);
                                return
                            }
                            try {
                                const resultAction = await dispatch(changePassword({oldPassword, newPassword}));
                                if (changePassword.fulfilled.match(resultAction)) {
                                    dispatch(setErrorMessage('Congratulations, your password has been successfully changed'))
                                }
                            } catch (error: any) {
                                if (error.codeNumber === 401) {
                                    dispatch(setErrorMessage('Incorrect old password. Please double check'))
                                }
                            }
                            setOldPassword('')
                            setNewPassword('')
                            setConfirmPassword('')
                            setOpen(false);
                        }}
                        noValidate autoComplete="off"
                    >
                        <Stack spacing={3}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="oldPassword"
                                label="Old password"
                                name="oldPassword"
                                type="password"
                                autoComplete="current-password"
                                value={oldPassword}
                                onChange={handleOldPasswordChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="newPassword"
                                label="New password"
                                name="newPassword"
                                type="password"
                                autoComplete="new-password"
                                value={newPassword}
                                onChange={handleNewPasswordChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="confirmPassword"
                                label="Confirm password"
                                name="confirmPassword"
                                type="password"
                                autoComplete="confirm-password"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                            />
                            <Button disabled={!isValid} type="submit">Submit</Button>
                            <Typography sx={{fontSize: '12px'}}>The password must be at least 8 characters long and
                                include at
                                least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character
                                !@#$%^&*</Typography>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
};

export default ChangePasswordBtn;