import {RequestRatesByIndexesBetween, StockData} from "../../utils/types";
import {fetchAllRatesBetween} from "./fetchAllRatesBetween";

interface CombinedData {
    maxDate: string,
    ticker1Rate: number | null,
    ticker2Rate: number | null,
}

export const fetchRatesData = async (request: RequestRatesByIndexesBetween) => {
    const fetchedRates = await fetchAllRatesBetween(request)
    if (fetchedRates && fetchedRates.length > 1) {

        const {tickerNames, combinedDataForChart} = convertDataForChart(fetchedRates, request)

        //Reducing the number of points for long periods
        const reducedCombinedData = pointReducer(combinedDataForChart)

        return {
            tickerNames,
            combinedData: reducedCombinedData
        }
    }
}

export const convertDataForChart = (fetchedRates: StockData[], request: RequestRatesByIndexesBetween) => {

    const {ticker1Rates, ticker2Rates} = splitFetchedDataByTickers(fetchedRates, request)

    let combinedDataForChart = Array.from(new Set([...ticker1Rates.map(d => d.maxDate), ...ticker2Rates.map(d => d.maxDate)]))
        .map(date => {
            const ticker1Entry = ticker1Rates.find(d => d.maxDate === date);
            const ticker2Entry = ticker2Rates.find(d => d.maxDate === date);
            return {
                maxDate: date,
                ticker1Rate: ticker1Entry ? ticker1Entry.endClose : null,
                ticker2Rate: ticker2Entry ? ticker2Entry.endClose : null,
            };
        }).sort((a, b) => new Date(a.maxDate).getTime() - new Date(b.maxDate).getTime());

    const tickerNames: string[] = [];
    if (ticker1Rates.length > 0) {
        tickerNames.push(ticker1Rates[0].source);
    }
    if (ticker2Rates.length > 0) {
        tickerNames.push(ticker2Rates[0].source);
    }

    return {tickerNames, combinedDataForChart}
}

export const splitFetchedDataByTickers = (fetchedRates: StockData[], request: RequestRatesByIndexesBetween) => {
    const ticker1Rates = fetchedRates
        .filter(item => item.source === request?.indexs[0])

    const ticker2Rates = fetchedRates
        .filter(item => item.source === request?.indexs[1])

    return {ticker1Rates, ticker2Rates}
}

export const pointReducer = (combinedDataForChart: CombinedData[]) => {
    const k = Math.ceil(combinedDataForChart.length / 50)
    if (k > 1) {
        const reducedCombinedData = []
        for (let i = 0; i < combinedDataForChart.length - 1; i = i + k) {
            reducedCombinedData.push(combinedDataForChart[i])
        }
        return reducedCombinedData
    }
    return combinedDataForChart
}