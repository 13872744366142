import React from "react";
import Box from "@mui/material/Box";
import Logo from "./header_and_footer/Logo";

const Footer = () => {
    return (
        <Box bgcolor={'#3e4953'}>
            <Box sx={{
                // maxWidth: '1200px',
                width: '100%',
                margin: '0 auto',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '60px'
            }}>
                {/*<Contacts color={'white'}/>*/}
                <Logo marginLeft={'50px'}/>
            </Box>
        </Box>
    );
}

export default Footer;