import React, {useEffect, useState} from 'react';
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import {Status} from "../../utils/constants";
import RequestForPasswordRecovery from "./RequestForPasswordRecovery";
import {useAppDispatch} from "../../app/hooks";
import {eraseErrorMessage} from "../../features/slices/errorSlice";

const Login = () => {
    const [status, setStatus] = useState(Status.signIn)
    const dispatch = useAppDispatch()

    const handleClickChangeRegister = () => {
        setStatus(Status.signUp)
        dispatch(eraseErrorMessage())
    }

    const handleClickChangeRecovery = () => {
        setStatus(Status.recoveryPassword)
        dispatch(eraseErrorMessage())
    }

    const handleClickSignIn = () => {
        setStatus(Status.signIn)
        dispatch(eraseErrorMessage())
    }

    useEffect(() => {
        dispatch(eraseErrorMessage())
    })

    switch (status) {
        case Status.signUp:
            return <SignUp changeToSignIn={handleClickSignIn}/>;
        case Status.recoveryPassword:
            return <RequestForPasswordRecovery changeToSignIn={handleClickSignIn}/>;
        default:
            return <SignIn changeToRegister={handleClickChangeRegister} changeToRecovery={handleClickChangeRecovery}/>;
    }
};

export default Login;