import React from 'react';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import style from "../../css_modules/header/header.module.css";

interface Props {
    to: string,
    key: string
    children: any
}

// Creating a Link component from React Router with styles of Button component from MUI
// Link component makes sure that the app isn't being reloaded while routing
const ButtonLink = (props: Props) => {

    return (
        <Button
            component={Link}
            to={props.to}
            className={style.navBtn}
        >
            {props.children}
        </Button>
    );
};

export default ButtonLink;