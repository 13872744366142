import React, {useState} from "react";
import Box from "@mui/material/Box";
import CorrelationForm from "./CorrelationForm";
import CorrelationChart from "./CorrelationChart";
import {RequestRatesByIndexesBetween} from "../../utils/types";
import {fetchRatesData} from "../../features/functions/fetchRatesData";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {clearCorrelationChartData, setCorrelationChartData,} from "../../features/slices/correlationChartSlice";
import {fetchCorreleationMessage} from "../../features/functions/fetchCorrelationMessage";
import {setErrorMessage} from "../../features/slices/errorSlice";

const CorrelationBox: React.FC = () => {
    const dispatch = useAppDispatch()
    const fetchRatesDataResult = useAppSelector(state => state.correlationChart.chartData)
    const [correlationMessage, setCorrelationMessage] = useState('')

    const handleCorrelationFormClick = async (request: RequestRatesByIndexesBetween) => {
        try {
            const fetchedData = await fetchRatesData(request)
            if (fetchedData) {
                dispatch(setCorrelationChartData(fetchedData))
                const fetchedCorrelationMessage = await fetchCorreleationMessage(request)
                if (fetchedCorrelationMessage && fetchedCorrelationMessage !== '') {
                    setCorrelationMessage(fetchedCorrelationMessage)
                } else {
                    dispatch(setErrorMessage('There is an issue at calc of correlation'))
                }
            } else {
                dispatch(clearCorrelationChartData())
                dispatch(setErrorMessage('Something went wrong. Incorrect data received from server'))
            }
        } catch (e: any) {

        }
    }

    return (
        <Box display={'flex'} justifyContent={'space-around'} alignItems={'center'}>
            <CorrelationForm onClick={handleCorrelationFormClick}/>
            <CorrelationChart showedInChartData={fetchRatesDataResult} correlationMessage={correlationMessage}/>
        </Box>
    )
}

export default CorrelationBox