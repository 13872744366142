import {createSlice} from "@reduxjs/toolkit";
import {TableData} from "../../utils/types";

const tableStatisticSlice = createSlice({
    name: 'tableStatistic',
    initialState: {
        ticker: '',
        dateFrom: '',
        dateTo: ''
    } as TableData,
    reducers: {
        setTableTicker(state, action) {
            return {
                ...state, ticker: action.payload
            }
        },
        setTableDateFrom(state, action) {
            return {
                ...state, dateFrom: action.payload
            }
        },
        setTableDateTo(state, action) {
            return {
                ...state, dateTo: action.payload
            }
        }
    }
})

export const {setTableDateFrom, setTableDateTo, setTableTicker} = tableStatisticSlice.actions
export default tableStatisticSlice.reducer