import {RequestRatesByIndexesBetween} from "../../utils/types";
import {store} from "../../app/store";
import {axiosBase} from "../../utils/config";

export const fetchCorreleationMessage = async (request: RequestRatesByIndexesBetween) => {
    const token = store.getState().token
    try {
        const response = await axiosBase.post(`/communication/index/correlation`, {
            indexs: request.indexs,
            from: request.from,
            to: request.to
        }, {
            headers: {
                Authorization: token
            }
        })
        return await response.data;
    } catch (e) {
        throw e
    }
}