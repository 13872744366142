import React, {lazy} from 'react';
import './App.css';
import Main from "./components/Main";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom';
import Login from "./components/accounting/Login";
import useTokenExpiration from "./features/functions/useTokenExpiration";
import Profile from "./components/accounting/Profile";
import PasswordRecovery from "./components/accounting/PasswordRecovery";
import PrivateRoute from "./components/PrivateRoute";
import StatisticsCorrelation from "./components/StatisticsCorrelation";
import NotFound from "./components/NotFound";

const Home = lazy(() => import("./components/Home"));
const StatisticsCandlestick = lazy(() => import("./components/StatisticsCandlestick"));
const Analytics = lazy(() => import("./components/Analytics"));
const CompanyContact = lazy(() => import("./components/CompanyContact"));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<Main/>}>
            <Route index element={<Home/>}/>
            <Route element={<PrivateRoute/>}>
                <Route path="/statistics/correlation" element={<StatisticsCorrelation/>}/>
                <Route path="/statistics/candlestick" element={<StatisticsCandlestick/>}/>
                <Route path="/analytics" element={<Analytics/>}/>
            </Route>
            <Route path={'contacts'} element={<CompanyContact/>}/>
            <Route path={'login'} element={<Login/>}/>
            <Route path={'profile'} element={<Profile/>}/>
            <Route path={'account/recovery/:token'} element={<PasswordRecovery/>}/>
            <Route path={'*'} element={<NotFound/>}/>
        </Route>
    )
)

const App = () => {
    useTokenExpiration();
    return (
        <RouterProvider router={router}/>
    );
}

export default App;
