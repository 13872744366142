import React from "react";
import Box from "@mui/material/Box";
import style from "../../css_modules/header/header.module.css";

type Props = {
    marginLeft: string
}

const Logo = ({marginLeft}:Props) => {
    return (
        <Box alignContent='center' marginLeft={marginLeft}>
            <Box component="span" className={style.logo} color="secondary">Stock<span>Stat</span></Box>
        </Box>
    )
}

export default Logo