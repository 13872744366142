import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/joy/Typography";

const InvestmentPortfolio = () => {
    return (
        <Box display="flex" sx={{height: '60vh', width: '100%', backgroundColor: 'rgb(222 225 255)'}}
             alignItems="center"
             justifyContent="center">
            <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
                Information about investment portfolio. dashboards, charts
            </Typography>
        </Box>
    );
};

export default InvestmentPortfolio;