import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import TickerInput from "../home/TickerInput";
import Button from '@mui/material/Button';
import {RequestRatesByIndexesBetween} from "../../utils/types";
import dayjs from "dayjs";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    setInputDateFrom,
    setInputDateTo,
    setInputTicker1,
    setInputTicker2
} from "../../features/slices/correlationChartSlice";
import useFetchTickers from "../../features/functions/useFetchTickers";
import {eraseErrorMessage, setErrorMessage} from "../../features/slices/errorSlice";
import {UserErrors} from "../../utils/constants";


interface CorrelationFormProps {
    onClick: (formData: RequestRatesByIndexesBetween) => void
}

const CorrelationForm: React.FC<CorrelationFormProps> = ({onClick}) => {
    const tickers = useFetchTickers();
    const dispatch = useAppDispatch();
    const inputFormData = useAppSelector(state => state.correlationChart.inputData)
    const [isValid, setIsValid] = useState(false);

    const handleOnChangeFirst = (value: string) => {
        dispatch(setInputTicker1(value));
    };

    const handleOnChangeSecond = (value: string) => {
        dispatch(setInputTicker2(value));
    };

    const handleClick = () => {
        onClick({
            indexs: [
                inputFormData.ticker1,
                inputFormData.ticker2
            ],
            type: "days",
            quantity: 1,
            from: inputFormData.dateFrom ? inputFormData.dateFrom : '',
            to: inputFormData.dateTo ? inputFormData.dateTo : ''
        })
    }

    useEffect(() => {
        const isValidDateFrom = dayjs(inputFormData.dateFrom, 'YYYY-MM-DD', true).isValid() && dayjs(inputFormData.dateFrom).isBefore(dayjs(inputFormData.dateTo));
        const isValidDateTo = dayjs(inputFormData.dateTo, 'YYYY-MM-DD', true).isValid() && dayjs(inputFormData.dateTo).isBefore(dayjs());
        const isValidTicker1 = !!(inputFormData.ticker1 && inputFormData.ticker1 !== '')
        const isValidTicker2 = !!(inputFormData.ticker2 && inputFormData.ticker2 !== '')
        if (dayjs(inputFormData.dateFrom).isAfter(dayjs(inputFormData.dateTo))) {
            dispatch(setErrorMessage(UserErrors.toBeforeFrom))
        } else if (dayjs(inputFormData.dateTo).isAfter(dayjs())) {
            dispatch(setErrorMessage(UserErrors.dateGreaterThanToday))
        } else {
            dispatch(eraseErrorMessage())
        }
        setIsValid(isValidDateFrom && isValidDateTo && isValidTicker1 && isValidTicker2);
    }, [inputFormData.dateFrom, inputFormData.dateTo, inputFormData.ticker1, inputFormData.ticker2, dispatch]);

    return (
        <Box
            component="form"
            sx={{
                width: '300px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '30px 10px',
                backgroundColor: '#f8f9fa',
                color: '#000',
                borderRadius: '2px',
                border: '1px solid #0000002b',
            }}
            noValidate
            autoComplete="off"
        >
            <Typography variant='h4' sx={{
                fontFamily: "'Merriweather', serif",
                fontSize: '23px',
                marginBottom: '20px',
                marginX: '10px',
                fontWeight: 500,
                lineHeight: 1.2,
                textAlign: 'center'
            }}>Select stocks and analysis period</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                    <Box display={'flex'}>
                        <DatePicker format="DD-MM-YYYY"
                                    value={inputFormData.dateFrom ? dayjs(inputFormData.dateFrom) : null}
                                    onChange={(newDate) => newDate && dispatch(setInputDateFrom(newDate.format('YYYY-MM-DD')))}
                                    maxDate={(inputFormData.dateTo ? dayjs(inputFormData.dateTo) : undefined) || dayjs()}
                                    sx={{
                                        color: '#595656',
                                        backgroundColor: 'white',
                                        borderRadius: '4px',
                                        marginRight: '10px',
                                        marginBottom: '30px'
                                    }} label="From"
                                    slotProps={{
                                        textField: {id: "corFormFrom"}
                                    }}
                        />
                        <DatePicker format="DD-MM-YYYY"
                                    value={inputFormData.dateTo ? dayjs(inputFormData.dateTo) : null}
                                    onChange={(newDate) => newDate && dispatch(setInputDateTo(newDate.format('YYYY-MM-DD')))}
                                    minDate={inputFormData.dateFrom ? dayjs(inputFormData.dateFrom) : undefined}
                                    maxDate={dayjs()}
                                    sx={{
                                        color: '#595656',
                                        backgroundColor: 'white',
                                        borderRadius: '4px',
                                        marginBottom: '30px'
                                    }} label="To"
                                    slotProps={{
                                        textField: {id: "corFormTo"}
                                    }}
                        />
                    </Box>
                </DemoContainer>
            </LocalizationProvider>
            {tickers && tickers[0] !== '' &&
                <Box width={'100%'}>
                    <TickerInput tickers={tickers} label={"First symbol"} id={'first-symbol'} marginBottom={'30px'}
                                 width={'100%'}
                                 onChange={handleOnChangeFirst}
                                 value={inputFormData.ticker1}
                    />
                    <TickerInput tickers={tickers} label={"Second symbol"} id={'second-symbol'} marginBottom={'30px'}
                                 width={'100%'}
                                 onChange={handleOnChangeSecond}
                                 value={inputFormData.ticker2}
                    />
                </Box>}
            <Box display={'flex'} justifyContent={'center'}>
                <Button variant="contained"
                        disabled={!isValid}
                        onClick={handleClick}
                        sx={{
                            width: '150px',
                            backgroundColor: '#7a1212',
                            '&:hover': {
                                backgroundColor: '#3e4953',
                                border: '1px solid #3e4953'
                            },
                            border: '1px solid #7a1212',
                            padding: '10px 20px',
                            color: 'white',
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                            fontSize: '18px'
                        }}>Calculate</Button>
            </Box>
        </Box>
    )
}

export default CorrelationForm