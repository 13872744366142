import React, {Suspense} from "react";
import Header from "./Header";
import Footer from "./Footer";
import {Outlet} from "react-router-dom";
import {ErrorMsgWrapper} from "../features/HOCs/ErrorMsgWrapper";

const Main = () => {

    return (
        <div>
            <Header/>
            <Suspense fallback={<h1>Loading...</h1>}>
                <Outlet/>
            </Suspense>
            <Footer/>
        </div>
    );
}

const WrappedMain = ErrorMsgWrapper(Main);

export default WrappedMain;