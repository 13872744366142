import React from "react";
import Box from "@mui/material/Box";
import ButtonLink from "../../features/HOCs/ButtonLink";

const LoginBtn = () => {
    return (
        <Box>
            <ButtonLink to='login' key='login'>Login</ButtonLink>
        </Box>

    );
}

export default LoginBtn;