import {createSlice} from "@reduxjs/toolkit";
import {CombinedRatesForCorrelationChart, InputDataForCorrelationChart} from "../../utils/types";

const correlationChartSlice = createSlice({
    name: 'correlationChart',
    initialState:
        {
            chartData: {
                tickerNames: [],
                combinedData: []
            } as CombinedRatesForCorrelationChart,
            inputData: {
                dateFrom: '',
                dateTo: '',
                ticker1: '',
                ticker2: ''
            } as InputDataForCorrelationChart
        },
    reducers: {
        setCorrelationChartData(state, action) {
            return {
                ...state,
                chartData: action.payload
            };
        },
        clearCorrelationChartData(state) {
            return {
                ...state,
                chartData: {
                    tickerNames: [],
                    combinedData: []
                }
            };
        },
        setInputDateFrom(state, action) {
            return {
                ...state,
                inputData: {
                    ...state.inputData,
                    dateFrom: action.payload
                }
            };
        },
        setInputDateTo(state, action) {
            return {
                ...state,
                inputData: {
                    ...state.inputData,
                    dateTo: action.payload
                }
            };
        },
        setInputTicker1(state, action) {
            return {
                ...state,
                inputData: {
                    ...state.inputData,
                    ticker1: action.payload
                }
            };
        },
        setInputTicker2(state, action) {
            return {
                ...state,
                inputData: {
                    ...state.inputData,
                    ticker2: action.payload
                }
            };
        }
    }
})

export const {
    setCorrelationChartData,
    clearCorrelationChartData,
    setInputDateFrom,
    setInputDateTo,
    setInputTicker1,
    setInputTicker2
} = correlationChartSlice.actions
export default correlationChartSlice.reducer
