import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import style from "../../css_modules/accounting/login.module.css";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import KeyIcon from '@mui/icons-material/Key';
import {useAppDispatch} from "../../app/hooks";
import {fetchUser} from "../../features/api/accountActions";
import {eraseErrorMessage, setErrorMessage} from "../../features/slices/errorSlice";
import {axiosBase} from "../../utils/config";

const PasswordRecovery = () => {
    const {token} = useParams();
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValid, setIsValid] = useState(false);

    const handleNewPasswordChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setConfirmPassword(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget)
        const newPassword = data.get('newPassword') as string
        const confirmPassword = data.get('confirmPassword') as string
        if (newPassword !== confirmPassword) {
            dispatch(setErrorMessage('Passwords do not match'))
            setNewPassword('')
            setConfirmPassword('')
            return
        }
        try {
            await axiosBase.post(`/account/recovery/${token}`, {}, {
                headers: {
                    'X-Password': newPassword
                }
            });
            const login = localStorage.getItem('login')
            const password = newPassword
            if (login) {
                dispatch(fetchUser({login, password}))
            }
            localStorage.removeItem('login')
            navigate('/')
        } catch (error) {

        }
    };

    useEffect(() => {
        const newPasswordValid = !!(newPassword && newPassword.length >= 8 && /[a-z]/.test(newPassword) && /[A-Z]/.test(newPassword) && /[0-9]/.test(newPassword) && /[!@#$%^&*]/.test(newPassword));
        const confirmPasswordValid = !!(confirmPassword && confirmPassword.length >= 8 && /[a-z]/.test(confirmPassword) && /[A-Z]/.test(confirmPassword) && /[0-9]/.test(confirmPassword) && /[!@#$%^&*]/.test(confirmPassword));
        setIsValid(newPasswordValid && confirmPasswordValid);
    }, [newPassword, confirmPassword]);

    useEffect(() => {
        if (isValid) {
            dispatch(eraseErrorMessage())
        }
    }, [isValid, dispatch])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box className={style.loginWindow}>
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <KeyIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Password recovery
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off" sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="newPassword"
                        label="New password"
                        type="password"
                        id="newPassword"
                        autoComplete="new-password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm password"
                        type="password"
                        id="confirmPassword"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={!isValid}
                        sx={{
                            mt: 3, mb: 2,
                            backgroundColor: '#7a1212',
                            '&:hover': {
                                backgroundColor: '#3e4953',
                                border: '1px solid #3e4953'
                            },
                        }}
                    >
                        Change password
                    </Button>
                    <Typography sx={{fontSize: '12px'}}>The password must be at least 8 characters long and include at
                        least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character
                        !@#$%^&*</Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default PasswordRecovery;