import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from "@mui/material/Box";
import {useAppDispatch} from "../../app/hooks";
import {PayloadAction} from "@reduxjs/toolkit";

export type Props = {
    msg: string,
    setMsg?: (msg: string) => void,
    positionRight?: string,
    top?: string,
    action?: () => PayloadAction;
}

const AlertComponent = ({msg, setMsg, action, positionRight, top}: Props) => {
    const dispatch = useAppDispatch();

    return (
        <Box sx={{
            position: 'fixed',
            right: positionRight || '1%',
            left: 'auto',
            top: top || `8%`,
            border: '2px solid darkRed',
            borderRadius: '6px',
            zIndex: 1000
        }}>
            {setMsg && <Alert severity="info" onClose={() => setMsg('')}>
                <AlertTitle>Info</AlertTitle>
                {msg}
            </Alert>}
            {action && <Alert severity="info" onClose={() => dispatch(action())}>
                <AlertTitle>Info</AlertTitle>
                {msg}
            </Alert>}
        </Box>
    );
};

export default AlertComponent;