import React, {useState} from 'react';
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import TextField from "@mui/material/TextField";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import {useAppDispatch} from "../../app/hooks";
import {setErrorMessage} from "../../features/slices/errorSlice";

interface AvatarProps {
    changeAvatar: (avatar: string) => void
}

const ChangeAvatarBtn: React.FC<AvatarProps> = ({changeAvatar}) => {
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch()

    return (
        <React.Fragment>
            <AddAPhotoIcon onClick={() => setOpen(true)}/>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog>
                    <DialogTitle>Please paste a link to new avatar</DialogTitle>
                    <form
                        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            const data = new FormData(event.currentTarget)
                            const avatar = data.get('avatar') as string | null
                            if (avatar) {
                                changeAvatar(avatar)
                            } else {
                                dispatch(setErrorMessage(`Please paste correct link`))
                            }
                            setOpen(false);
                        }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="avatar"
                            label="New avatar"
                            name="avatar"
                            autoComplete="avatar"
                            autoFocus
                        />
                        <Button type="submit">Submit</Button>
                    </form>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
};

export default ChangeAvatarBtn;