import React, {useEffect, useState} from 'react';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import style from "../../css_modules/accounting/login.module.css";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import MailLockIcon from '@mui/icons-material/MailLock';
import {axiosBase} from "../../utils/config";
import {eraseErrorMessage} from "../../features/slices/errorSlice";
import {useAppDispatch} from "../../app/hooks";

interface Props {
    changeToSignIn: () => void
}

const RequestForPasswordRecovery = ({changeToSignIn}: Props) => {
    const [message, setMessage] = useState('Please indicate your login/email to which a link to reset your password will be sent');
    const [isValid, setIsValid] = useState(false);
    const [email, setEmail] = useState('');
    const dispatch = useAppDispatch()

    const handleEmailChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setEmail(event.target.value);
    };

    useEffect(() => {
        const emailValid = !!(email && email.includes('@'));
        setIsValid(emailValid);
    }, [email]);

    useEffect(() => {
        if (isValid) {
            dispatch(eraseErrorMessage())
        }
    }, [isValid, dispatch])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setMessage('')
        const data = new FormData(event.currentTarget)
        const login = data.get('email') as string
        try {
            const response = await axiosBase.get(`/account/recovery/${login}`);
            if (response) {
                localStorage.setItem('login', login as string)
                setMessage(`We've sent you the link for password recovery to indicated email. Please follow the link and change your password`)
            } else {
                setMessage(`Please enter correct email`)
            }
        } catch (error) {

        }

    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box className={style.loginWindow}>
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <MailLockIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Password recovery
                </Typography>
                <Typography component="h1" variant="inherit">
                    {message}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off" sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <Button
                        type="submit"
                        // onClick={handleSubmit}
                        fullWidth
                        variant="contained"
                        disabled={!isValid}
                        sx={{
                            mt: 3, mb: 2,
                            backgroundColor: '#7a1212',
                            '&:hover': {
                                backgroundColor: '#3e4953',
                                border: '1px solid #3e4953'
                            },
                        }}
                    >
                        Send the recovery link
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link href="#" variant="body2" onClick={changeToSignIn}>
                                {"Get back to SignIn"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
};

export default RequestForPasswordRecovery;