import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {fillTickersNames} from '../api/tickersActions';
import {KEY_TICKERS_STORAGE} from "../../utils/constants";
import dayjs from "dayjs";
import {isUpToDate, saveToLocalStorage} from "../../utils/utils";
import {setTickers, setTickersMessage} from "../slices/tickersSlice";
import {useEffect, useRef} from "react";

let isFetched = false;

const useFetchTickers = () => {
    let tickers = useAppSelector((state) => state.tickers.name);
    const lastUpdate = useAppSelector((state) => state.tickers.dateChecker);
    const dispatch = useAppDispatch();
    // Flag to make sure a cleanup function in useEffect works (it's being checked by React strict mode
    // firing useEffects twice)
    const effectCalled = useRef(false);

    useEffect(() => {
        dispatch(setTickersMessage(''));
        // Check if tickers are in global state and up to date. If not, trying to get tickers from storage
        // If not in storage, fetching from API and saving to global state and to storage (inside 'fillStockName')
        if (!isFetched && (!tickers || !isUpToDate(dayjs(lastUpdate).valueOf(), 2)) && !effectCalled.current) {
            isFetched = true;
            const storedRaw = localStorage.getItem(KEY_TICKERS_STORAGE);
            const stored: { data: string[], lastUpdated: number } = storedRaw ? JSON.parse(storedRaw) : null;
            if (stored && isUpToDate(stored.lastUpdated, 2)) {
                dispatch(setTickers(stored.data));
            } else {
                dispatch(
                    fillTickersNames()
                )
                    .unwrap()
                    .then((tickers: string[]) => {
                        saveToLocalStorage(KEY_TICKERS_STORAGE, tickers);
                    })
                    .catch(e => {
                        // dispatch(setTickersMessage(`Failed to fetch tickers: ${e}`));
                    });
            }
        }
        return () => {
            effectCalled.current = true;
            isFetched = false;
        }
    }, [dispatch, lastUpdate, tickers]);
    return tickers;
};

export default useFetchTickers;
