import {createSlice} from "@reduxjs/toolkit";
import {InputDataForLandingChart, RatesForLandingChart} from "../../utils/types";

const landingChartSlice = createSlice({
    name: 'landingChart',
    initialState:
        {
            chartData: {
                data: []
            } as RatesForLandingChart,
            inputData: {
                dateFrom: '',
                dateTo: '',
                ticker: ''
            } as InputDataForLandingChart
        },
    reducers: {
        setLandingChartData(state, action) {
            return {
                ...state,
                chartData: {
                    data: action.payload
                }
            };
        },
        setInputDateFrom(state, action) {
            return {
                ...state,
                inputData: {
                    ...state.inputData,
                    dateFrom: action.payload
                }
            };
        },
        setInputDateTo(state, action) {
            return {
                ...state,
                inputData: {
                    ...state.inputData,
                    dateTo: action.payload
                }
            };
        },
        setInputTicker(state, action) {
            return {
                ...state,
                inputData: {
                    ...state.inputData,
                    ticker: action.payload
                }
            };
        }
    }
})

export const {
    setLandingChartData,
    setInputDateFrom,
    setInputDateTo,
    setInputTicker
} = landingChartSlice.actions
export default landingChartSlice.reducer
