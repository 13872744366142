import * as React from 'react';
import {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import style from '../../css_modules/accounting/login.module.css'
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useNavigate} from "react-router-dom";
import {eraseErrorMessage, setErrorMessage} from "../../features/slices/errorSlice";
import {fetchUser} from "../../features/api/accountActions";

interface Props {
    changeToRegister: () => void,
    changeToRecovery: () => void
}

export default function SignIn({changeToRegister, changeToRecovery}: Props) {

    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.token)
    const navigate = useNavigate();
    const [isValid, setIsValid] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmailChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setPassword(event.target.value);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (isValid) {
            handleSubmit(event);
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget)
        let login = data.get('email') as string
        const password = data.get('password') as string
        try {
            login = login.toLowerCase()
            await dispatch(fetchUser({login, password})).unwrap()
        } catch (error: any) {
            if (error.codeNumber && error.codeNumber === 401) {
                dispatch(setErrorMessage('Incorrect login or password. Please double check'))
            } else {
                dispatch(setErrorMessage('Unknown error occurred'))
            }
        }
    };

    useEffect(() => {
        const emailValid = !!(email && email.includes('@'));
        const passwordValid = !!(password && password !== '');
        setIsValid(emailValid && passwordValid);
    }, [email, password]);

    useEffect(() => {
        if (isValid) {
            dispatch(eraseErrorMessage())
        }
    }, [isValid, dispatch])

    useEffect(() => {
        if (token) {
            navigate('/'); // Redirect to your desired route
        }
    }, [navigate, token]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box className={style.loginWindow}>
                <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate autoComplete="off" sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    <Button
                        type="submit"
                        // onClick={handleSubmit}
                        disabled={!isValid}
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 3, mb: 2,
                            backgroundColor: '#7a1212',
                            '&:hover': {
                                backgroundColor: '#3e4953',
                                border: '1px solid #3e4953'
                            },
                        }}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2" onClick={changeToRecovery}>
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2" onClick={changeToRegister}>
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </Container>
    );
}
