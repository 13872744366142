import {createSlice} from "@reduxjs/toolkit";
import {UserProfile} from "../../utils/types";
import {deleteAccount, fetchUser, registerUser, updateUser} from "../api/accountActions";

const storedUserData = localStorage.getItem('userData');
const initialState: UserProfile = storedUserData ? JSON.parse(storedUserData) : {};

const userSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        deleteUser: () => ({} as UserProfile),
    },
    extraReducers: builder => {
        builder
            .addCase(registerUser.fulfilled, (state, action) => {
                return action.payload?.registeredUser
            })
            .addCase(registerUser.rejected, (state, action) => {
                throw action.payload
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                return action.payload.loginedUser
            })
            .addCase(fetchUser.rejected, (state, action) => {
                throw action.payload
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                return action.payload
            })
            .addCase(updateUser.rejected, (state, action) => {
                throw action.payload
            })
            .addCase(deleteAccount.fulfilled, () => {
                return initialState
            })
            .addCase(deleteAccount.rejected, (state, action) => {
                throw action.payload
            })
    }
})

export const {deleteUser} = userSlice.actions
export default userSlice.reducer