import React from 'react';
import Box from "@mui/material/Box";

type Props = {
    text: string
    style: {}
}

const TextBox = ({text, style}: Props) => {
    return (
        <Box sx={{textAlign: 'center', ...style}}>
            {text}
        </Box>
    );
};

export default TextBox;