// export const base_url = 'https://finstats.herokuapp.com';

// export const base_url = 'https://stocker.up.railway.app';

// export const base_url = 'https://stockstatservice-prod.up.railway.app'

export const base_url = process.env.REACT_APP_API_URL

if (!base_url) {
    throw new Error('REACT_APP_API_URL environment variable is not defined');
}

export const createToken = (login: string, password: string) => `Basic ${window.btoa(login + ':' + password)}`;

export const contacts = {
    email: 'oll@investing.com',
    tel: '+972 53 111111'
}

export const GOOGLE_API_KEY = 'AIzaSyD1H_2REisuOqD8LboqzilqZoD5zj6bGTA';
export const ADDRESS = 'Prof. Menakhem Plaut St 10, Rehovot';

export const KEY_TICKERS_STORAGE = 'StockStats/tickers';

export enum Status {
    signIn = 'LOGIN',
    signUp = 'REGISTER',
    recoveryPassword = 'RECOVERY PASSWORD'
}

export const serverErrors: Record<string, string> = {
    error401: 'Please sign in to your account',
    error403: 'Access denied due to permissions',
    error404: `Page doesn't exist`,
    error405: `Error 405. Please message us if you faced this issue`,
    error409: `User with such login is already exist`,
    error417: `There is no user with this email. Please check the email provided`,
    error500: 'No response from the server. Please try again later'
}

export enum UserErrors {
    ticker = 'Please select the required ticker',
    date = 'Both dates must be entered',
    toBeforeFrom = `The dates are incorrect. Make sure the date From is less than the date To. And also that the dates are current`,
    dateGreaterThanToday = `The date is incorrect. Make sure the date isn't greater than today`
}