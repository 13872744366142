import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useAppDispatch} from "../../app/hooks";
import {deleteToken} from "../../features/slices/tokenSlice";
import {deleteUser} from "../../features/slices/userSlice";
import {Link, useNavigate} from "react-router-dom";
import {eraseErrorMessage} from "../../features/slices/errorSlice";

const UserBtn = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        dispatch(eraseErrorMessage())
        setAnchorElUser(null);
    };

    const handleClickLogout = () => {
        dispatch(deleteToken());
        dispatch(deleteUser());
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        localStorage.removeItem('tokenTimestamp');
        setAnchorElUser(null);
        navigate('/');
    }

    //TODO add photo to avatar
    return (
        <Box sx={{flexGrow: 0}}>
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu}>
                    <Avatar alt="test" src=""/>
                </IconButton>
            </Tooltip>
            <Menu
                sx={{mt: '45px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem component={Link} to='profile' key='profile' onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem key='logout' onClick={handleClickLogout}>
                    <Typography textAlign="center">Logout</Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default UserBtn