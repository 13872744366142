import React from "react";
import Stack from "@mui/material/Stack";
import ButtonLink from "../../features/HOCs/ButtonLink";
import StatisticsBtn from "./StatisticsBtn";

const Navigation = () => {

    return (
        <Stack direction="row" spacing={2}>
            <ButtonLink to={'/'} key={'/'}>{'Home'}</ButtonLink>
            <StatisticsBtn/>
            <ButtonLink to={'analytics'} key={'analytics'}>{'Analytics'}</ButtonLink>
            <ButtonLink to={'contacts'} key={'contacts'}>{'Contacts'}</ButtonLink>
        </Stack>
    );
}

export default Navigation;
