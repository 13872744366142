import {createSlice} from "@reduxjs/toolkit";

const errorSlice = createSlice({
    name: 'error',
    initialState: null as string | null,
    reducers: {
        setErrorMessage: (state, action) => (action.payload),
        eraseErrorMessage: () => null
    },
})

export const {setErrorMessage, eraseErrorMessage} = errorSlice.actions
export default errorSlice.reducer