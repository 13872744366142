import React from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {useAppDispatch} from "../../app/hooks";
import {deleteAccount} from "../../features/api/accountActions";

const DeleteAccountBtn = () => {
    const [open, setOpen] = React.useState<boolean>(false);
    const dispatch = useAppDispatch()

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                color="danger"
                endDecorator={<DeleteForever/>}
                onClick={() => setOpen(true)}
                sx={{maxWidth: '90px'}}
            >
                Delete
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <WarningRoundedIcon/>
                        Confirmation
                    </DialogTitle>
                    <Divider/>
                    <DialogContent>
                        Are you sure you want to delete your account? This action cannot be undone.
                    </DialogContent>
                    <DialogActions>
                        <Button variant="solid" color="danger" onClick={async () => {
                            setOpen(false)
                            try {
                                await dispatch(deleteAccount())
                            } catch (error: any) {

                            }
                        }}>
                            Delete account
                        </Button>
                        <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
};

export default DeleteAccountBtn;