import React from 'react';
import UserProfile from "./UserProfile";
import InvestmentPortfolio from "./InvestmentPortfolio";
import Box from "@mui/material/Box";

const Profile = () => {

    return (
        <Box>
            <UserProfile/>
            <InvestmentPortfolio/>
        </Box>
    )
};

export default Profile;