import {createAsyncThunk} from "@reduxjs/toolkit";
import {axiosBase} from "../../utils/config";


export const fillTickersNames = createAsyncThunk<string[], void>(
    'tickers/getNames',
    async () => {
        try {
            const response = await axiosBase.get<string[]>(`/communication/index`)
            const tickers: string[] = response.data;
            return tickers;
        } catch (error) {
            throw error;
        }
    })
