import React, {useEffect, useState} from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import Stack from '@mui/joy/Stack';
import {updateUser} from "../../features/api/accountActions";
import {useAppDispatch} from "../../app/hooks";
import TextField from "@mui/material/TextField";
import {eraseErrorMessage} from "../../features/slices/errorSlice";

const ChangeDataBtn = () => {
    const [open, setOpen] = React.useState<boolean>(false);
    const dispatch = useAppDispatch()
    const [isValid, setIsValid] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const handleFirstNameChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setLastName(event.target.value);
    };

    useEffect(() => {
        const firstNameValid = !!(firstName && firstName !== '');
        const lastNameValid = !!(lastName && lastName !== '');
        setIsValid(firstNameValid && lastNameValid);
    }, [firstName, lastName]);

    useEffect(() => {
        if (isValid) {
            dispatch(eraseErrorMessage())
        }
    }, [isValid, dispatch])

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                color="neutral"
                onClick={() => setOpen(true)}
            >
                Change user data
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog>
                    <DialogTitle>Please input new data</DialogTitle>
                    <form
                        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            const data = new FormData(event.currentTarget)
                            const firstName = data.get('firstName') as string
                            const lastName = data.get('lastName') as string
                            try {
                                dispatch(updateUser({firstName, lastName}))
                            } catch (e) {

                            }
                            setOpen(false);
                            setFirstName('')
                            setLastName('')
                        }}
                    >
                        <Stack spacing={2}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                name="firstName"
                                autoComplete="firstName"
                                autoFocus
                                value={firstName}
                                onChange={handleFirstNameChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="lastName"
                                label="Surname"
                                name="lastName"
                                autoComplete="lastName"
                                autoFocus
                                value={lastName}
                                onChange={handleLastNameChange}
                            />
                            <Button disabled={!isValid} type="submit">Submit</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
};

export default ChangeDataBtn;