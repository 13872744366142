import {createSlice} from "@reduxjs/toolkit";
import {fillTickersNames} from "../api/tickersActions";
import dayjs from "dayjs";

const tickersSlice = createSlice({
    name: 'tickers',
    initialState: {
        name: [''],
        dateChecker: '',
        status: '',
        message: ''
    },
    reducers: {
        setTickers(state, action) {
            state.name = action.payload;
            state.dateChecker = dayjs().format('YYYY-MM-DD');
        },
        setTickersMessage(state, action) {
            return {...state, message: action.payload}
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fillTickersNames.fulfilled, (state, action) => {
                state.name = action.payload;
                state.dateChecker = dayjs().format('YYYY-MM-DD');
            })
        // .addCase(fillTickersNames.rejected, (state, action) => {
        //     state.message = action.payload || 'Unknown error';
        // })
    }
})

export const {setTickers, setTickersMessage} = tickersSlice.actions;
export default tickersSlice.reducer