import React from "react";
import {Autocomplete, TextField} from "@mui/material";

type Props = {
    tickers: string[],
    label: string,
    id: string,
    marginBottom: string,
    width: string,
    onChange?: (value: string) => void,
    marginRight?: string,
    defaultValue?: string,
    value?: string | null
}
const TickerInput = ({
                         tickers,
                         label,
                         id,
                         marginBottom,
                         width,
                         onChange,
                         marginRight,
                         defaultValue,
                         value
                     }: Props) => {

    const isValidValue = value && tickers.includes(value) ? value : null;

    return (
        <Autocomplete
            id={id}
            options={tickers}
            isOptionEqualToValue={(option, value) => option === value}
            defaultValue={defaultValue ?? null}
            onChange={(event, value) => onChange ? onChange(value as string) : null}
            renderInput={(params) => <TextField {...params} label={label}/>}
            value={isValidValue}
            sx={{
                width: {width}, marginBottom: {marginBottom}, color: '#595656', backgroundColor: 'white',
                borderRadius: '4px', marginRight: {marginRight}
            }}
        />
    )
}

export default TickerInput