import {createSlice} from "@reduxjs/toolkit";
import {ChartWithIrrData, DataPoint} from "../../components/analytics/StockAnalyticsByIrr";
import {AnalyticsStockDataWithIrr, GetIndexDataDto, RequestRatesByIndexesBetween} from "../../utils/types";
import {fetchAnalyticsWithIrrThunk} from "../api/irrActions";


export interface IrrSliceData {
    irrData: ChartWithIrrData,
    lineData: DataPoint[],
    userInputFromInvestmentCalc: RequestRatesByIndexesBetween,
    // error: string
}

export const initialState: IrrSliceData = {
    irrData: {} as ChartWithIrrData,
    lineData: {} as DataPoint[],
    userInputFromInvestmentCalc: {
        indexs: [''],
        from: '',
        to: '',
        type: 'years',
        quantity: 1
    },
    // error: ''
}


const irrAnalyticsSlice = createSlice({
    name: 'irrAnalytics',
    initialState,
    reducers: {
        setUserInput(state, action) {
            state.userInputFromInvestmentCalc = action.payload;
        },
        // resetError(state) {
        //     state.error = '';
        // },
        // setError(state, action){
        //     state.error = action.payload
        // }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAnalyticsWithIrrThunk.fulfilled, (state, action) => {
                state.irrData = parseFetchedDataWithIrrForChart(action.payload.irrData);
                state.lineData = parseFetchedDataForLineChart(action.payload.lineData);
            })
    }
})

const parseFetchedDataWithIrrForChart = (fetchedDataWithIrr: AnalyticsStockDataWithIrr) => {
    return {
        ticker: fetchedDataWithIrr?.source[0],
        fromDate: fetchedDataWithIrr.from,
        toDate: fetchedDataWithIrr.to,
        minIrr: Number(fetchedDataWithIrr.minIncome.apy),
        maxIrr: Number(fetchedDataWithIrr.maxIncome.apy),
        minIncomePeriod: [
            {
                x: fetchedDataWithIrr.minIncome.dateOfPurchase,
                y: fetchedDataWithIrr.minIncome.purchaseAmount
            },
            {
                x: fetchedDataWithIrr.minIncome.dateOfSale,
                y: fetchedDataWithIrr.minIncome.saleAmount
            }],
        maxIncomePeriod: [
            {
                x: fetchedDataWithIrr.maxIncome.dateOfPurchase,
                y: fetchedDataWithIrr.maxIncome.purchaseAmount
            },
            {
                x: fetchedDataWithIrr.maxIncome.dateOfSale,
                y: fetchedDataWithIrr.maxIncome.saleAmount
            }
        ]
    };
}

const parseFetchedDataForLineChart = (fetchedLineData: GetIndexDataDto[]): DataPoint[] => {
    return fetchedLineData.map(data => ({
        x: data.date,
        y: data.close
    }))
}

export const { setUserInput } = irrAnalyticsSlice.actions;
export default irrAnalyticsSlice.reducer;