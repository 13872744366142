import {createAsyncThunk} from "@reduxjs/toolkit";
import {MyKnownError, UserCredentials, UserData, UserProfile, UserRegister, UserUpdatePass} from "../../utils/types";
import {createToken} from "../../utils/constants";
import {RootState} from "../../app/store";
import {axiosBase} from "../../utils/config";

export const registerUser = createAsyncThunk(
    'user/register',
    async (user: UserRegister, {rejectWithValue}) => {
        try {
            const response = await axiosBase.post(`/account/register`, user)
            const registeredUser = await response.data
            const token = createToken(user.login, user.password)
            localStorage.setItem('token', token);
            localStorage.setItem('userData', JSON.stringify(registeredUser));
            localStorage.setItem('tokenTimestamp', JSON.stringify(Date.now()));
            return {registeredUser, token}
        } catch (error: any) {
            return rejectWithValue({
                ...error,
                codeNumber: error.response.status,
            });
        }
    }
)

export const fetchUser = createAsyncThunk(
    'user/login',
    async (user: UserCredentials, {rejectWithValue}) => {
        try {
            const token = createToken(user.login, user.password)
            const response = await axiosBase.post(`/account/login`, user, {
                headers: {
                    Authorization: token
                }
            });
            const loginedUser = await response.data;
            localStorage.setItem('token', token);
            localStorage.setItem('userData', JSON.stringify(loginedUser));
            localStorage.setItem('tokenTimestamp', JSON.stringify(Date.now()));
            return {loginedUser, token}
        } catch (error: any) {
            return rejectWithValue({
                ...error,
                codeNumber: error.response ? error.response.status : null,
            });
        }
    }
)

export const updateUser = createAsyncThunk<UserProfile, UserData, { state: RootState }>(
    'user/update',
    async (user: UserData, {getState, rejectWithValue}) => {
        if (getState().token) {
            try {
                const response = await axiosBase.put(`/account/user/${getState().user.login}`, user, {
                    headers: {
                        Authorization: getState().token
                    }
                })
                const updatedUser = await response.data;
                localStorage.setItem('userData', JSON.stringify(updatedUser));
                return updatedUser
            } catch (error: any) {
                return rejectWithValue({
                    ...error,
                    codeNumber: error.response.status,
                });
            }
        }
    }
)

export const changePassword = createAsyncThunk<string, UserUpdatePass, { state: RootState, rejectValue: MyKnownError }>(
    'user/changePassword',
    async (password, thunkAPI) => {
        try {
            await axiosBase.put(`/account/password`, {}, {
                headers: {
                    Authorization: createToken(thunkAPI.getState().user.login, password.oldPassword),
                    'X-Password': password.newPassword
                }
            })
            const token = createToken(thunkAPI.getState().user.login, password.newPassword)
            localStorage.setItem('token', token);
            return token
        } catch (error: any) {
            return thunkAPI.rejectWithValue({
                ...error,
                codeNumber: error.response.status,
            });
        }
    }
)

export const deleteAccount = createAsyncThunk<UserProfile, void, { state: RootState }>(
    'user/deleteAccount',
    async (_, {getState, rejectWithValue}) => {
        try {
            const response = await axiosBase.delete(`/account/user/${getState().user.login}`, {
                headers: {
                    Authorization: getState().token
                }
            })
            const deletedUser = await response.data;
            localStorage.removeItem('token');
            localStorage.removeItem('userData');
            localStorage.removeItem('tokenTimestamp');
            return deletedUser
        } catch (error: any) {
            return rejectWithValue({
                ...error,
                codeNumber: error.response.status,
            });
        }
    }
)
