import React from 'react';
import Box from "@mui/material/Box";
import TextBox from "../home/TextBox";

const TextCorrelation = () => {

    const row1 = "ANALYZE THE RELATIONSHIP BETWEEN TWO STOCKS";
    const row2 = "Assessing the Dependence of Two Stocks";
    const row3 = "Discover the correlation coefficient between any two stocks with our easy-to-use calculator. Make informed investment decisions based on the data-driven analysis of stock market trends"

    return (
        <Box>
            <TextBox text={row1} key={1} style={{fontSize: '17px', marginBottom: '7px', color: '#3e4953'}}/>
            <TextBox text={row2} key={2}
                     style={{fontSize: '35px', marginBottom: '15px', fontFamily: "'Merriweather', serif"}}/>
            <TextBox text={row3} key={3}
                     style={{margin: '0 auto 50px', width: '60%', fontSize: '16px', color: '#3e4953'}}/>
        </Box>
    );
};

export default TextCorrelation;
