import {RequestRatesByIndexesBetween, StockData} from "../../utils/types";
import {axiosBase} from "../../utils/config";
import {store} from "../../app/store";
import {setErrorMessage} from "../slices/errorSlice";
import {UserErrors} from "../../utils/constants";
import dayjs from "dayjs";

export const fetchAllRatesBetween = async (body: RequestRatesByIndexesBetween) => {
    if (body.indexs[0] === '' || body.indexs[0] === null) {
        store.dispatch(setErrorMessage(UserErrors.ticker))
    }
    if (body.from === '' || body.to === '') {
        store.dispatch(setErrorMessage(UserErrors.date))
        return
    }
    if (dayjs(body.from).isAfter(dayjs(body.to))) {
        store.dispatch(setErrorMessage(UserErrors.toBeforeFrom))
        return
    }
    try {
        const response = await axiosBase.post(`/communication/data`, body)
        const data: StockData[] = response.data
        return data
    } catch (e) {

    }
}