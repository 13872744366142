import React from "react";
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';
import {CombinedRatesForCorrelationChart} from "../../utils/types";
import {Typography} from "@mui/material";
import Box from "@mui/joy/Box";

interface CorrelationChartProps {
    showedInChartData: CombinedRatesForCorrelationChart,
    correlationMessage: string
}

const CorrelationChart: React.FC<CorrelationChartProps> = ({showedInChartData, correlationMessage}) => {

    function removeFirstWord(str: string) {
        const firstSpaceIndex = str.indexOf(' ');
        if (firstSpaceIndex === -1) {
            return '';
        }
        return str.substring(firstSpaceIndex + 1);
    }

    return (
        <Box>
            <LineChart width={800} height={450} data={showedInChartData.combinedData}
                       margin={{top: 5, right: 20, bottom: 5, left: 0}}>
                {showedInChartData.tickerNames[0] &&
                    <Line type="monotone" dataKey="ticker1Rate" name={showedInChartData.tickerNames[0]}
                          stroke="#8884d8"/>}
                {showedInChartData.tickerNames[1] &&
                    <Line type="monotone" dataKey="ticker2Rate" name={showedInChartData.tickerNames[1]}
                          stroke="#82ca9d"/>}
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                <XAxis dataKey='maxDate'/>
                <YAxis domain={['dataMin - 10', 'dataMax + 10']} tickFormatter={(tick: string): string => {
                    const tickNumber = +tick
                    return Math.floor(tickNumber).toString()
                }}/>
                <Legend/>
                <Tooltip/>
            </LineChart>
            <Box display={'flex'} justifyContent={'center'}>
                {correlationMessage && correlationMessage === 'No correlation' &&
                    <Typography variant={'h6'}>{correlationMessage}</Typography>}
                {correlationMessage && correlationMessage !== 'No correlation' &&
                    <Typography variant={'h6'}>{removeFirstWord(correlationMessage)}. Correlation
                        coefficient is {Number(correlationMessage.split(' ')[0]).toFixed(3)}</Typography>}
            </Box>
        </Box>

    )
}

export default CorrelationChart